import { useMutation } from "hooks/useMutation";
import { financialPackagesApi } from "./api";
import { createApiQuery } from "hooks/createApiQuery";
import { MG } from "utilities/mockGenerator";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { FinancialWarehouseStateMonthStatus } from "./financial-warehouse-state/enums";
import { WarehouseIconKind } from "../models";

const useFinancialPackageStateSummary = createApiQuery(
  financialPackagesApi.getFinancialPackageStateSummary,
  () => ({
    date: MG.isoDateTime(),
    estimatedValue: MG.number({ min: 1, max: 10000 }),
    value: MG.number({ min: 1, max: 10000 }),
  }),
);

const useFinancialPackageStateSummaryPerPeriods = createPaginatedApiQuery(
  financialPackagesApi.getFinancialPackageStateSummaryPerPeriods,
  () => ({
    id: MG.uuid(),
    periodId: "2024-12",
    status: FinancialWarehouseStateMonthStatus.OPENED,
    value: MG.number({ min: 1, max: 10000 }),
  }),
);

const useFinancialPackageDocuments = createPaginatedApiQuery(
  financialPackagesApi.getFinancialPackageSupplies,
  () => ({
    receivedCount: MG.number({ min: 1, max: 200 }),
    releasedCount: MG.number({ min: 1, max: 200 }),
    unitPrice: MG.number({ min: 1, max: 999 }),
    whEntry: {
      closedAt: MG.isoDateTime(),
      id: MG.number({ min: 1, max: 9999 }).toString(),
      signature: MG.signature({ collectionType: "RECEPTIONS" }),
    },
  }),
);

const useFinancialPackageInventoryChecks = createPaginatedApiQuery(
  financialPackagesApi.getFinancialPackageInventoryChecks,
  () => ({
    created: MG.isoDateTime(),
    difference: MG.number({ min: 1, max: 200 }),
    id: MG.number({ min: 1, max: 9999 }),
    inventoryCheck: MG.number({ min: 1, max: 200 }),
    inWarehouse: MG.number({ min: 1, max: 200 }),
    signature: MG.signature({ collectionType: "INVENTORY_CHECK" }),
    unitPrice: MG.number({ min: 1, max: 100000 }),
    warehouse: {
      code: MG.string({ length: 6 }),
      icon: "ROUND" as WarehouseIconKind,
      id: MG.id(),
      name: MG.companyName(),
    },
  }),
);

const useCalculatePeriod = (open: () => void) => {
  return useMutation(financialPackagesApi.postCalculatePeriod, ({ queryUtils }) => ({
    ...queryUtils.invalidateOnSuccessAndHandleError,
    onSuccess: () => {
      open();
      queryUtils.invalidateOnSuccessAndHandleError.onSuccess();
    },
  }));
};

const useClosePeriod = () => {
  return useMutation(
    financialPackagesApi.postClosePeriod,
    ({ queryUtils }) => queryUtils.invalidateOnSuccessAndHandleError,
  );
};

export const financialPackagesActions = {
  useCalculatePeriod,
  useClosePeriod,
  useFinancialPackageDocuments,
  useFinancialPackageInventoryChecks,
  useFinancialPackageStateSummary,
  useFinancialPackageStateSummaryPerPeriods,
};
