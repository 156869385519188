import { queryFetch } from "apiConnectors/queryFetch";
import {
  CalculatePeriodDTO,
  ClosePeriodDTO,
  FinancialPackageDocument,
  FinancialPackageInventoryCheck,
  PackageStateSummary,
  PackageStateSummaryPerPeriod,
} from "./models";
import { ApiFetcher } from "hooks/createApiQuery";
import { financialPackagesKeys } from "./keys";
import { Pagination } from "api/types";

const postCalculatePeriod = (data: CalculatePeriodDTO) =>
  queryFetch<{ message: "ok" }>({
    method: "POST",
    url: "/wms/financial-packages/calculate-period",
    data,
  });

const postClosePeriod = (data: ClosePeriodDTO) =>
  queryFetch<{ message: "ok" }>({
    method: "POST",
    url: "/wms/financial-packages/close-period",
    data,
  });

const getFinancialPackageStateSummary = (internalId: string): ApiFetcher<PackageStateSummary> => ({
  key: financialPackagesKeys.packageState.summary(internalId),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/view-modules/financial-packages/package-state-summary/" + internalId,
    }),
});

const getFinancialPackageStateSummaryPerPeriods = (
  search: string = "",
): ApiFetcher<Pagination<PackageStateSummaryPerPeriod>> => ({
  key: financialPackagesKeys.packageState.perPeriods(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/view-modules/financial-packages/package-state-per-periods" + search,
    }),
});

const getFinancialPackageSupplies = (
  search: string = "",
): ApiFetcher<Pagination<FinancialPackageDocument>> => ({
  key: financialPackagesKeys.packageState.supplies(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/view-modules/financial-packages/package-supplies" + search,
    }),
});

const getFinancialPackageInventoryChecks = (
  search: string = "",
): ApiFetcher<Pagination<FinancialPackageInventoryCheck>> => ({
  key: financialPackagesKeys.inventoryChecks.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/view-modules/financial-packages/package-inventory-checks" + search,
    }),
});

export const financialPackagesApi = {
  getFinancialPackageInventoryChecks,
  getFinancialPackageSupplies,
  getFinancialPackageStateSummary,
  getFinancialPackageStateSummaryPerPeriods,
  postCalculatePeriod,
  postClosePeriod,
};
