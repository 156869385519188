import { Order, OrderProductInstance } from "api/orders/models";
import { Partials } from "api/other/models";
import { ProductEntity } from "api/products/models";
import { FLAVOR } from "CONSTANTS";
import { AdaptedOrder, AdaptedProduct, Values } from "../ProductForm";
import { getAttributesState } from "./getAttributesState";
import { findPreselectedValues } from "./findPreselectedValues";
import cuid from "cuid";

export const initialProductElement: Omit<
  Values["productElements"][number],
  "id" | "attributesState" | "name" | "productSetCode" | "product" | "_product" | "cuid"
> = {
  amount: "",
  quantity: 1,
  currency: "PLN",
  note: "",
  index: null,
};

export function getInitialStateForEdit(
  products: OrderProductInstance[],
  productSetCode: string,
  indexes: Record<string, number>,
): Values {
  const productElements = products.map(productElement => {
    const selectedIndex = productElement.index;
    const preselectedAttributes = findPreselectedValues(productElement);
    const name = (() => {
      if (FLAVOR === "b2b") return productElement.name;

      return productElement.product.name;
    })();

    return {
      ...initialProductElement,
      attributesState: getAttributesState(
        productElement.product.attributes,
        indexes,
        preselectedAttributes,
        selectedIndex,
      ),
      cuid: cuid(),
      name,
      productSetCode: productSetCode,
      id: productElement.id,
      quantity: productElement.quantity,
      amount: productElement.amount as string,
      currency: productElement.currency,
      note: productElement.note,
      product: productElement.product.id,
    };
  });
  return { productElements };
}

export function getInitialStateForCreate({
  products,
  productSetCode,
  customers,
  orderCustomer,
  orderSalesAccount,
  indexes,
}: {
  products: ProductEntity["products"];
  productSetCode: ProductEntity["productSetCode"];
  customers: Partials["customers"];
  orderCustomer: Order["customer"];
  orderSalesAccount: AdaptedOrder["salesAccount"];
  indexes: Record<string, number>;
}): Values {
  const productElements = products.map(productElement => {
    const index = getProductIndex(productElement, indexes) || productElement.setMetaData?.index;
    if (index && orderCustomer && !orderCustomer.canAddNewIndexes) {
      const attributesState = getAttributesState(productElement.attributes, indexes, [], index);
      const amount = String(productElement.indexData[index].amount);
      const currency = getCustomerPriceList(orderCustomer.id, customers)?.currency || "PLN";

      return {
        ...initialProductElement,
        quantity: productElement.setMetaData?.quantity || 1,
        cuid: cuid(),
        attributesState,
        name: productElement.name,
        productSetCode: productSetCode,
        id: productElement.id,
        amount,
        currency,
        product: productElement.id,
      };
    }

    if (productElement.setMetaData?.index) {
      const attributesState = getAttributesState(
        productElement.attributes,
        indexes,
        [],
        productElement.setMetaData.index,
      );

      return {
        ...initialProductElement,
        quantity: productElement.setMetaData?.quantity || 1,
        cuid: cuid(),
        attributesState,
        name: productElement.name,
        productSetCode: productSetCode,
        id: productElement.id,
        amount: "0",
        currency: orderSalesAccount.currency || "PLN",
        product: productElement.id,
      };
    }

    return {
      ...initialProductElement,
      quantity: productElement.setMetaData?.quantity || 1,
      name: productElement.name,
      productSetCode: productSetCode,
      attributesState: getAttributesState(productElement.attributes, indexes),
      id: productElement.id,
      cuid: cuid(),
      amount: "0",
      currency: orderSalesAccount.currency || "PLN",
      product: productElement.id,
    };
  });
  return { productElements };
}

function getCustomerPriceList(customerId: number, customers: Partials["customers"]) {
  const customer = customers.find(el => el.id === customerId);
  if (customer) {
    return customer.priceList;
  }
  return null;
}

const getProductIndex = (
  product: AdaptedProduct | null,
  indexes: Record<string, number>,
  preselectedAttributes?: any,
) => {
  const attributes = getAttributesState(product?.attributes, indexes, preselectedAttributes);

  const attributesValues = Object.values(attributes).map(attr => attr.valueId);
  const allSelected = attributesValues.length > 0 && attributesValues.every(val => Boolean(val));
  if (allSelected) {
    const values = [...(attributesValues as number[])].sort((a, b) => (a > b ? 1 : -1));
    const index = values.join("-");
    if (product) {
      return indexes[index] || null;
    }
  }

  return null;
};
