import { useMemo } from "react";
import { AdaptedOrder, AttributesState, PreselectedValues } from "../ProductForm";
import { AttributeAccordion } from "../attributeAccordion/AttributeAccordion";
import { FormRadioLabels } from "components/utils/formRadioLabels";
import { Form } from "components/common/form/Form";
import { Product, ProductAttribute } from "api/products/models";
import { productsActions } from "api/products/actions";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { queryString } from "utilities";

interface Props {
  attributesToDisplay: (ProductAttribute & { cuid: string })[] | null;
  preselectedAttributes: PreselectedValues;
  order?: AdaptedOrder;
  attributesState: AttributesState;
  changeAttribute: (
    attributeId: number,
    value: number | null,
    attributesState: AttributesState,
    productIndexes: Record<string, number>,
  ) => void;
  isCommonAttributes?: boolean;
  productsIds: Product["id"][];
}

export const AttributeSection = ({
  order,
  preselectedAttributes,
  attributesState,
  changeAttribute,
  attributesToDisplay,
  isCommonAttributes,
  productsIds,
}: Props) => {
  const { data: productIndexes, isLoading } = productsActions.useGetProductIndexes(
    queryString.stringify({ products: productsIds }),
    {
      enabled: Boolean(order?.customer && !order?.customer.canAddNewIndexes && productsIds.length),
    },
  );

  const possibleValues = useMemo(() => {
    const indexes = Object.keys(productIndexes || {});

    const values: string[][] = [];
    indexes.forEach(index => {
      const addedIndexes = attributesState.map(el => el.valueId).filter(Boolean);
      const indexArray = index.split("-");

      if (addedIndexes.every(el => indexArray.includes(String(el)))) {
        values.push(indexArray);
      }
    });

    return [...new Set(values.flat())].map(Number);
  }, [attributesState, productIndexes]);

  function isValueDisabled(value: number) {
    if (!order?.customer) return false;
    if (order?.customer.canAddNewIndexes) return false;
    if (!possibleValues.includes(value)) {
      return true;
    }
    return false;
  }

  if (!attributesToDisplay) return null;
  if (isLoading) return <Spinner size={20} />;

  return (
    <div>
      {attributesToDisplay
        .filter(attr => !preselectedAttributes.some(el => el.id === attr.id))
        .map(attribute => {
          const stateValue = attributesState.find(el => el.attributeId === attribute.id);
          if (attribute.kind === "PICTURE") {
            return (
              <div className="d-flex" key={attribute.cuid}>
                <FormRadioLabels label={attribute.name}>
                  {attribute.values
                    .filter(el => el.isAssignableToIndex)
                    .map((value, _, arr) => (
                      <FormRadioLabels.Label
                        key={`${value.id}-${attribute.cuid}`}
                        label={value.name}
                        name={`attribute-${attribute.id}-${attribute.cuid}`}
                        value={value.id}
                        checked={stateValue && stateValue.valueId === value.id}
                        onChange={({ value }) => {
                          if (isCommonAttributes) {
                            changeAttribute(attribute.id, value, attributesState, productIndexes!);
                          }
                          if (arr.length > 1) {
                            changeAttribute(attribute.id, value, attributesState, productIndexes!);
                          }
                        }}
                        type="image"
                        img={value.picture}
                        disabled={isValueDisabled(value.id)}
                        className="mb-1 mr-1"
                      />
                    ))}
                </FormRadioLabels>
              </div>
            );
          }

          if (attribute.kind === "FABRIC") {
            return (
              <div key={attribute.cuid}>
                <hr className="hr" />
                <Form.FieldsetLabel className="mt-3">{attribute.name}</Form.FieldsetLabel>
                {attribute.categories
                  .filter(category => category.values.some(val => val.isAssignableToIndex))
                  .map(category => {
                    const hasSelectedValue = category.values.some(
                      val => val.id === stateValue?.valueId,
                    );

                    return (
                      <div key={`${category.name}-${attribute.cuid}`}>
                        <AttributeAccordion
                          label={category.name}
                          enabled={true}
                          isSelected={hasSelectedValue}
                        >
                          <div className="d-flex">
                            <FormRadioLabels>
                              {category.values
                                .filter(el => el.isAssignableToIndex)
                                .map((value, _, arr) => (
                                  <FormRadioLabels.Label
                                    key={`${value.id}-${attribute.cuid}`}
                                    label={value.name}
                                    name={`attribute-${attribute.id}-${attribute.cuid}`}
                                    value={value.id}
                                    checked={stateValue && stateValue.valueId === value.id}
                                    onChange={({ value }) => {
                                      if (isCommonAttributes) {
                                        changeAttribute(
                                          attribute.id,
                                          value,
                                          attributesState,
                                          productIndexes!,
                                        );
                                      }

                                      if (arr.length > 1) {
                                        changeAttribute(
                                          attribute.id,
                                          value,
                                          attributesState,
                                          productIndexes!,
                                        );
                                      }

                                      changeAttribute(
                                        attribute.id,
                                        value,
                                        attributesState,
                                        productIndexes!,
                                      );
                                    }}
                                    type="image"
                                    img={value.picture}
                                    disabled={isValueDisabled(value.id)}
                                    className="mb-1 mr-1"
                                  />
                                ))}
                            </FormRadioLabels>
                          </div>
                        </AttributeAccordion>
                      </div>
                    );
                  })}
              </div>
            );
          }

          return (
            <div key={attribute.cuid}>
              <FormRadioLabels label={attribute.name}>
                {attribute.values
                  .filter(el => el.isAssignableToIndex)
                  .map((value, _, arr) => {
                    return (
                      <FormRadioLabels.Label
                        key={`${value.id}-${attribute.cuid}`}
                        label={value.name}
                        name={`attribute-value-${attribute.id}-${attribute.cuid}`}
                        value={value.id}
                        checked={stateValue && stateValue.valueId === value.id}
                        onChange={({ value }) => {
                          if (isCommonAttributes) {
                            changeAttribute(attribute.id, value, attributesState, productIndexes!);
                          }

                          if (arr.length > 1) {
                            changeAttribute(attribute.id, value, attributesState, productIndexes!);
                          }
                        }}
                        type="image"
                        img={value.picture}
                        disabled={isValueDisabled(value.id)}
                        className="mb-1 mr-1"
                      />
                    );
                  })}
              </FormRadioLabels>
            </div>
          );
        })}
    </div>
  );
};
